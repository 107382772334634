import React from "react";
import { AlertCircle, UserPlus, BookOpen, Mail } from "lucide-react";

const LandingPage = () => {
  return (
    <div className="min-h-screen bg-gray-100">
      <header className="bg-blue-600 text-white p-4">
        <div className="container mx-auto flex justify-between items-center">
          <h1 className="text-2xl font-bold">EduAccess Advocates</h1>
          <nav>
            <ul className="flex space-x-4">
              <li>
                <a href="#about" className="hover:underline">
                  About
                </a>
              </li>
              <li>
                <a href="#services" className="hover:underline">
                  Services
                </a>
              </li>
              <li>
                <a href="#contact" className="hover:underline">
                  Contact
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </header>

      <main className="container mx-auto mt-8 p-4">
        <section id="hero" className="text-center mb-12">
          <h2 className="text-4xl font-bold mb-4">
            Empowering Students with Disabilities
          </h2>
          <p className="text-xl mb-6">
            We advocate for equal educational opportunities for all students,
            regardless of their abilities.
          </p>
          <button className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded">
            Get Involved
          </button>
        </section>
        <section className="mb-12 relative">
          <div className="h-64 md:h-96 bg-gray-300 relative overflow-hidden">
            <img
              src="/students.png"
              alt="Students we've helped"
              className="w-full h-full object-cover md:object-[center_30%]"
            />
            <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
              <div className="text-white text-center">
                <h3 className="text-3xl font-bold mb-2">Making a Difference</h3>
                <p className="text-xl">
                  Over 500 students empowered and counting
                </p>
              </div>
            </div>
          </div>
        </section>
        <section id="about" className="mb-12">
          <h3 className="text-2xl font-bold mb-4">About Us</h3>
          <p className="mb-4">
            EduAccess Advocates is a Protection & Advocacy (P&A) group dedicated
            to ensuring that students with disabilities receive the support and
            accommodations they need to succeed in their educational journey.
          </p>
          <p>
            We work tirelessly to protect the rights of students with various
            disabilities, including but not limited to:
          </p>
          <ul className="list-disc list-inside mb-4">
            <li>Deafness and hearing impairments</li>
            <li>Blindness and visual impairments</li>
            <li>Mobility disabilities</li>
            <li>Learning disabilities</li>
            <li>Autism spectrum disorders</li>
            <li>Mental health conditions</li>
          </ul>
        </section>

        <section id="services" className="mb-12">
          <h3 className="text-2xl font-bold mb-4">Our Services</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            <div className="bg-white shadow-md rounded-lg p-6">
              <h4 className="text-xl font-semibold mb-2 flex items-center">
                <AlertCircle className="mr-2" />
                Advocacy
              </h4>
              <p>
                We advocate for policy changes and improved accommodations in
                educational institutions.
              </p>
            </div>
            <div className="bg-white shadow-md rounded-lg p-6">
              <h4 className="text-xl font-semibold mb-2 flex items-center">
                <UserPlus className="mr-2" />
                Individual Support
              </h4>
              <p>
                We provide personalized assistance to students and families
                navigating the education system.
              </p>
            </div>
            <div className="bg-white shadow-md rounded-lg p-6">
              <h4 className="text-xl font-semibold mb-2 flex items-center">
                <BookOpen className="mr-2" />
                Education
              </h4>
              <p>
                We offer workshops and resources to educate communities about
                disability rights in education.
              </p>
            </div>
          </div>
        </section>

        <section id="contact" className="mb-12">
          <h3 className="text-2xl font-bold mb-4">Contact Us</h3>
          <p className="mb-4">
            If you need assistance or want to support our cause, please don't
            hesitate to reach out.
          </p>
          <button className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded flex items-center">
            <Mail className="mr-2" />
            Contact Us
          </button>
        </section>
      </main>

      <footer className="bg-gray-800 text-white p-4 mt-8">
        <div className="container mx-auto text-center">
          <p>&copy; 2024 EduAccess Advocates. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;
